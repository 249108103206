module.exports = [{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/node_modules/gatsby-plugin-core-js/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito","Open Sans","Material Icons","Roboto"]}},
    },{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://4b0d9209c748435db76022a2a34ca223@sentry.io/1414998","environment":"production","enabled":true},
    },{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"N6aBMrKNsLkV2GoVKlkZ8dCDk4boOH1Y","trackPage":true},
    },{
      plugin: require('/codebuild/output/src351540637/src/github.com/aramoj/ari-dental-www/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
